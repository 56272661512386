import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filter_button = _resolveComponent("filter-button")!
  const _component_filter_dialog = _resolveComponent("filter-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_filter_button, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openFilters())),
      loading: _ctx.filtrosLoading
    }, null, 8, ["loading"]),
    (_ctx.filtrosLoading === false)
      ? (_openBlock(), _createBlock(_component_filter_dialog, {
          key: 0,
          width: "auto",
          modelValue: _ctx.dialog,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dialog) = $event)),
          onCancelar: _cache[2] || (_cache[2] = ($event: any) => (_ctx.dialog = false)),
          onAplicar: _cache[3] || (_cache[3] = ($event: any) => (_ctx.aplicar()))
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ], 64))
}