
import axios from "@/axios-config";
import { defineComponent, ref, computed } from "vue";
import { useStore } from "vuex";

import * as FT from "../../../interfaces/filtrosGlobais";

import LoadingDefault from "@/components/LoadingDefault.vue";

export default defineComponent({
  components: {
    LoadingDefault,
  },
  props: {
    filtro: {
      type: Object as () => FT.filtro,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    let filtroStore = ref(store.state.filtros[props.filtro.filterName]);

    function addFilter(item: FT.filterItem) {
      item.selected = !item.selected;

      if (item.selected === true) {
        let nomeFiltro = props.filtro.filterName;
        let code = item[props.filtro.cod];

        store.commit("addFiltro", { nomeFiltro, code });
      }

      if (item.selected === false) {
        let nomeFiltro = props.filtro.filterName;
        let code = item[props.filtro.cod];

        store.commit("removeFilter", { nomeFiltro, code });
      }

      filtroItems.value.sort((a, b) => {
        if (!a.selected && b.selected) {
          return 1;
        }

        return -1;
      });

      filtroStore.value = store.state.filtros[props.filtro.filterName];
    }

    let loading = ref(true);
    let firstTime = ref(true);
    let filtroItems = ref<FT.filterItem[]>([]);

    function getFilter() {
      firstTime.value = false;

      axios
        .get(`/Manager/${props.filtro.endpoint}`)
        .then((res: FT.FiltrosResponse) => {
          filtroItems.value = res.data.filter(
            (e) => e[props.filtro.descricao] !== undefined,
          );

          filtroItems.value.forEach((el) => {
            if (filtroStore.value.includes(el[props.filtro.cod])) {
              el.selected = true;
            }
          });

          loading.value = false;
        })
        .catch(() => {
          store.commit("defineSnack", {
            text: "Falha ao carregar filtros.",
            active: true,
            type: "error",
          });
        });
    }

    let buscar = ref("");

    let orderedFilters = computed(() => {
      let term = buscar.value || "";

      return filtroItems.value.filter((e) =>
        e[props.filtro.descricao].toLowerCase().includes(term),
      );
    });

    let isExpanded = ref(false);
    function searchFilters() {
      isExpanded.value = !isExpanded.value;

      if (isExpanded.value && firstTime.value) {
        getFilter();
      }
    }

    function clearSelecteds() {
      filtroItems.value.forEach((e) => {
        e.selected = false;
      });

      let nomeFiltro = props.filtro.filterName;
      store.commit("clearFilters", nomeFiltro);

      filtroStore.value = store.state.filtros[props.filtro.filterName];
    }

    return {
      addFilter,
      getFilter,
      isExpanded,
      searchFilters,
      loading,
      filtroItems,
      buscar,
      orderedFilters,
      clearSelecteds,
      filtroStore,
    };
  },
});
