
import { defineComponent } from "vue";

import FilterButton from "./FilterButton.vue";
import FilterDialog from "./FilterDialog.vue";

export default defineComponent({
  components: { FilterButton, FilterDialog },
  data() {
    return {
      dialog: false,

      filtrosLoading: false,
    };
  },
  methods: {
    openFilters() {
      this.dialog = !this.dialog;
    },
    cancelar() {
      this.dialog = false;
    },

    aplicar() {
      this.dialog = false;

      this.$store.commit("defineChangeFilter", 1);
    },
  },
});
