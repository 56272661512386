
import { defineComponent } from "vue";

import GlobalFilters from "./GlobalFilter/GlobalFilters.vue";

export default defineComponent({
  components: {
    GlobalFilters,
  },
});
