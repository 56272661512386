
import { defineComponent } from "vue";
import LoadingBoxes from "./components/LoadingBoxes.vue";
import MainView from "./views/MainView.vue";
import LoginView from "./views/LoginView.vue";
import MenuUserDesktop from "@/components/MainTemplate/MenuUserDesktop.vue";
import MenuUserMobile from "@/components/MainTemplate/MenuUserMobile.vue";

export default defineComponent({
  name: "App",
  components: {
    LoadingBoxes,
    MainView,
    LoginView,
    MenuUserDesktop,
    MenuUserMobile,
  },

  data() {
    return {
      loadingSession: true,
    };
  },

  mounted() {
    document.title = "Painel do Distribuidor";
    document.documentElement.style.setProperty("--nav-width", "300px");
    this.$axios
      .get("/Usuarios/isAuthenticated")
      .then(() => {
        this.loadingSession = false;
      })
      .catch(() => {
        this.loadingSession = false;
        this.$store.commit("defineLogged", false);
      });
  },

  methods: {
    logoff() {
      this.$store.commit("defineSessaoExpirada", false);
      document.cookie = "session=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      this.$router.push("/login");
    },
  },
});
