
import { defineComponent } from "vue";
import * as RT from "../../interfaces/rotas";

export default defineComponent({
  props: {
    isMobile: Boolean,
    navDesktopOpened: Boolean,
    items: Array as () => RT.rotas[] | null,

    indent: {
      type: Number,
      required: true,
    },
  },
  methods: {
    redirectRoute(item: RT.rotas) {
      if (this.isMobile) {
        item.expanded = !item.expanded;
      }

      if (!item.children) {
        this.$store.commit("routeRedirected", 1);
        this.$router.push({ path: item.path });
      }
    },
  },
});
