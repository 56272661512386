
import { defineComponent } from "vue";
import axios from "@/axios-config";
export default defineComponent({
  data() {
    return {
      email: null,
      buttonLoading: false,
      ErrorLogin: false,

      success: false,
      dialog: false,
    };
  },

  methods: {
    recover() {
      this.buttonLoading = true;

      let params = {
        email: this.email,
      };

      axios
        .post("/Usuarios/resetPassword", params)
        .then(() => {
          this.successRecover();
        })
        .catch(() => {
          this.errorRecover();
        });

      // setTimeout(() => {
      //   this.successRecover();
      // }, 1000);
      //this.errorRecover();
    },
    successRecover() {
      this.buttonLoading = false;
      this.success = true;
      this.$store.commit("defineSnack", {
        text: "Enviamos um e‑mail para você. Siga as instruções para redefinir sua senha.",
        active: true,
        type: "success",
      });
    },

    errorRecover() {
      this.buttonLoading = false;

      this.$store.commit("defineSnack", {
        text: "Falha ao enviar o e-mail.",
        active: true,
        type: "error",
      });
    },
  },
});
