import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import axios from "@/axios-config";

import Highcharts from "highcharts";
import mapInit from "highcharts/modules/map";
import mapData from "@highcharts/map-collection/custom/world.geo.json";
import patternFill from "highcharts/modules/pattern-fill";
import HC_more from "highcharts/highcharts-more";
HC_more(Highcharts);

mapInit(Highcharts);
patternFill(Highcharts);
Highcharts.maps["myMapName"] = mapData;

import "./css/reset.css";
import "./css/variables.css";
import "./css/global.css";

loadFonts();

const app = createApp(App).use(router).use(store).use(vuetify);

app.config.globalProperties.$axios = axios;

app.config.globalProperties.$formatNumber = function (amount, fractionDig = 2) {
  return amount.toLocaleString("pt-br", {
    minimumFractionDigits: fractionDig,
    maximumFractionDigits: fractionDig,
  });
};

app.mount("#app");
