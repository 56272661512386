
import { defineComponent } from "vue";

// Components

export default defineComponent({
  name: "MenuIcon",

  props: {
    navMobileOpened: {
      type: Boolean,
      required: true,
    },
  },
});
