
import { defineComponent } from "vue";
import LoginForm from "./LoginForm.vue";
import RecoverPass from "./RecoverPass.vue";

export default defineComponent({
  name: "App",
  components: { LoginForm, RecoverPass },
  data() {
    return {
      imgCliente: require("../../assets/logo_cliente_login.png"),
      imgImplanta: require("../../assets/logo_implanta_desenvolvimento.svg"),

      buttonLoading: false,

      ErrorLogin: false,

      mode: "login",

      title: "PAINEL DO DISTRIBUIDOR",

      slide_in: false,
      slide_out: false,

      recover_dialog: false,
    };
  },

  methods: {
    esqueceu() {
      this.slide_out = true;
      this.slide_in = false;

      setTimeout(() => {
        this.mode = "recover";
        this.title = "Recuperar senha";
        this.slide_in = true;
      }, 300);
    },

    resetLoginScreen() {
      this.slide_out = true;
      this.slide_in = false;

      setTimeout(() => {
        this.mode = "login";
        this.title = "PAINEL DO DISTRIBUIDOR";
        this.slide_in = true;
      }, 300);
    },
  },
});
