
import { defineComponent } from "vue";

import * as FT from "../../../interfaces/filtrosGlobais";

import BoxFilter from "./BoxFilter.vue";

export default defineComponent({
  components: {
    BoxFilter,
  },
  data() {
    return {
      filtros: [
        {
          name: "Categorias",
          endpoint: "getCategorias",
          descricao: "descricaoCategoria",
          cod: "codigoCategoria",

          filterName: "CategoriaProduto",
        },

        {
          name: "Familias",
          endpoint: "getFamilias",
          descricao: "descricaoFamilia",
          cod: "codigoFamilia",

          filterName: "FamiliaProduto",
        },
        {
          name: "Grupos Empresariais",
          endpoint: "getGruposEmpresariais",
          descricao: "descricaoGrupoEmpresarial",
          cod: "codigoGrupoEmpresarial",

          filterName: "GrupoEmpresarial",
        },
        {
          name: "Linha Produtos",
          endpoint: "getLinhaProdutos",
          descricao: "descricaoLinhaProduto",
          cod: "codigoLinhaProduto",

          filterName: "LinhaProduto",
        },
        {
          name: "Regioes",
          endpoint: "getRegioes",
          descricao: "descricaoRegiao",
          cod: "codigoRegiao",

          filterName: "Regiao",
        },
        {
          name: "Estados",
          endpoint: "getEstados",
          descricao: "descricaoUF",
          cod: "codigoUF",

          filterName: "Estado",
        },
        {
          name: "Municipios",
          endpoint: "getMunicipios",
          descricao: "descricaoMunicipio",
          cod: "codigoIBGE",

          filterName: "Municipio",
        },
        {
          name: "Produtos",
          endpoint: "GetProdutos",
          descricao: "descricaoProduto",
          cod: "codigoProduto",

          filterName: "Produto",
        },

        {
          name: "Revendas",
          endpoint: "getRevendas",
          descricao: "nomeRazaoRevenda",
          cod: "codigoRevenda",

          filterName: "Revenda",
        },
      ] as FT.filtro[],
    };
  },

  methods: {
    cancelar() {
      this.$emit("cancelar");
    },
    aplicar() {
      this.$emit("aplicar");
    },
  },
});
