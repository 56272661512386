import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },

  {
    path: "/:catchAll(.*)",
    name: "404",
    component: () => import("../views/NotFoundView.vue"),
  },

  {
    path: "/vmi",
    name: "vmi",
    component: () => import("../views/VmiView.vue"),

    meta: {
      title: "Gestão de estoque",
      //vicon: "mdi-cube",
      requiresAuth: true,
    },
  },

  {
    path: "/",
    name: "home",
    component: () => import("../views/VisaoGeral.vue"),

    meta: {
      title: "Visão Geral",
      vicon: "mdi-cube",
      expanded: false,
      requiresAuth: true,
    },
  },
  {
    path: "/sellout",
    name: "sellout",
    component: () => import("../views/SelloutView.vue"),

    meta: {
      title: "SellOut",
      vicon: "mdi-chart-line-variant",
      requiresAuth: true,
      expanded: false,
    },
  },

  {
    path: "/cidades",
    name: "cidades",
    component: () => import("../views/CidadeView.vue"),
    meta: {
      title: "Cidades Atendidas",
      //vicon: "mdi-map-marker-radius",
      requiresAuth: true,
      expanded: false,
    },
  },
  {
    path: "/compras",
    name: "compras",
    component: () => import("../views/ComprasView.vue"),

    meta: {
      title: "Compras",
      //vicon: "mdi-cart-variant",
      requiresAuth: true,
      expanded: false,
    },
  },

  {
    path: "/positivacao-pdv",
    name: "positivacaopdv",
    component: () => import("../views/PositivacaoPDVView.vue"),
    meta: {
      title: "Positivação PDV",
      vicon: "mdi-cart-variant",
      requiresAuth: true,
      expanded: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = document.cookie
    .split("; ")
    .find((cookie) => cookie.startsWith("session="));
  const logged = !!token;

  if (to.matched.some((record) => record.meta.requiresAuth) && !logged) {
    next("/login");
  } else {
    if (to.name === "login" && logged) {
      next("/");
    } else {
      next();
    }
  }
});

export default router;
