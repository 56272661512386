import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bbc6cf78"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "box_filter" }
const _hoisted_2 = {
  key: 0,
  class: "limpar_selecao"
}
const _hoisted_3 = { class: "right_side" }
const _hoisted_4 = {
  key: 0,
  class: "number_filter"
}
const _hoisted_5 = {
  key: 0,
  class: "filters_container"
}
const _hoisted_6 = { class: "filter_tools" }
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_loading_default = _resolveComponent("loading-default")!
  const _component_v_text_field = _resolveComponent("v-text-field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "box_title",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchFilters()))
    }, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.filtro.name) + ":", 1),
      (_ctx.filtroStore.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_v_btn, {
              icon: "mdi-trash-can-outline",
              title: "Remover seleção",
              class: "search_icon_actions",
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.clearSelecteds()), ["stop"]))
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.filtroStore.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.filtroStore.length), 1))
          : _createCommentVNode("", true),
        (_ctx.isExpanded)
          ? (_openBlock(), _createBlock(_component_v_btn, {
              key: 1,
              icon: "mdi-menu-down",
              class: "search_icon_actions"
            }))
          : (_openBlock(), _createBlock(_component_v_btn, {
              key: 2,
              icon: "mdi-menu-right",
              class: "search_icon_actions"
            }))
      ])
    ]),
    (_ctx.isExpanded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_loading_default, {
                key: 0,
                class: "loading_box_filter"
              }))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_v_text_field, {
                    label: "buscar",
                    modelValue: _ctx.buscar,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.buscar) = $event)),
                    "hide-details": "",
                    clearable: ""
                  }, null, 8, ["modelValue"])
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderedFilters, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(["filter_type", { active: item.selected }]),
                    onClick: ($event: any) => (_ctx.addFilter(item)),
                    key: item[_ctx.filtro.cod]
                  }, _toDisplayString(item[_ctx.filtro.descricao]), 11, _hoisted_7))
                }), 128))
              ], 64))
        ]))
      : _createCommentVNode("", true)
  ]))
}