import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ee8d6936"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onMouseenter", "onMouseleave", "onClick"]
const _hoisted_2 = { class: "contentMenu" }
const _hoisted_3 = {
  key: 0,
  class: "menu_text fade_in"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_list_child = _resolveComponent("list-child")!

  return (_openBlock(), _createElementBlock("ul", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus, (item) => {
      return (_openBlock(), _createElementBlock("li", {
        key: item.path,
        onMouseenter: ($event: any) => (
        !_ctx.isMobile ? (item.expanded = true) : (item.expanded = item.expanded)
      ),
        onMouseleave: ($event: any) => (
        !_ctx.isMobile ? (item.expanded = false) : (item.expanded = item.expanded)
      ),
        onClick: _withModifiers(($event: any) => (_ctx.redirectRoute(item)), ["stop"]),
        class: _normalizeClass(["menu", { flexColumn: _ctx.isMobile, activeMenu: _ctx.isActive(item) }])
      }, [
        _createElementVNode("div", _hoisted_2, [
          _withDirectives(_createVNode(_component_v_icon, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.meta?.vicon), 1)
            ]),
            _: 2
          }, 1536), [
            [_vShow, item.meta?.vicon]
          ]),
          (_ctx.isMobile || _ctx.navDesktopOpened)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(item.meta?.title), 1))
            : _createCommentVNode("", true)
        ]),
        _withDirectives(_createElementVNode("div", null, [
          _createVNode(_component_list_child, {
            items: item.children,
            isMobile: _ctx.isMobile,
            navDesktopOpened: _ctx.navDesktopOpened,
            indent: 40
          }, null, 8, ["items", "isMobile", "navDesktopOpened"])
        ], 512), [
          [_vShow, item.expanded]
        ])
      ], 42, _hoisted_1))
    }), 128))
  ]))
}