import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-99e1e640"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main_view" }
const _hoisted_2 = { class: "main_template_area" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_nav = _resolveComponent("main-nav")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_main_nav),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component, route }) => [
          _createVNode(_Transition, { name: "slide" }, {
            default: _withCtx(() => [
              (_openBlock(), _createElementBlock("div", {
                class: "wrapper_router_view",
                key: route.path
              }, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
              ]))
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      })
    ])
  ]))
}