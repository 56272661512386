
import { defineComponent } from "vue";
import ListChild from "./ListChild.vue";

import * as RT from "../../interfaces/rotas";

export default defineComponent({
  components: { ListChild },
  props: {
    isMobile: Boolean,

    navDesktopOpened: Boolean,
  },
  data() {
    return {
      menus: [] as RT.rotas[],
    };
  },

  mounted() {
    let items = this.$router.options.routes.filter((e) => {
      return !!e.meta?.vicon;
    });
    this.menus = [];

    items.forEach((e) => {
      this.menus.push({
        meta: e.meta as any,
        level: 1,
        children: e.children as any,
        path: e.path.toString(),
        expanded: false,
      });
    });
  },

  methods: {
    isActive(item: RT.rotas): boolean {
      if (item.path === this.$route.matched[0].path) {
        return true;
      }

      return false;
    },
    redirectRoute(item: RT.rotas) {
      if (this.isMobile) {
        item.expanded = !item.expanded;
      }

      if (!item.children) {
        this.$store.commit("routeRedirected", 1);
        this.$router.push({ path: item.path });
      }
    },
  },
});
