import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mainLogin" }
const _hoisted_2 = { class: "loginLeft" }
const _hoisted_3 = { class: "loginRight" }
const _hoisted_4 = { class: "container_img_login" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  class: "img_implanta",
  style: {"color":"#000"}
}
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_login_form = _resolveComponent("login-form")!
  const _component_recover_pass = _resolveComponent("recover-pass")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["login_title", {
          slide_out: _ctx.slide_out,
          slide_in: _ctx.slide_in,
        }])
      }, _toDisplayString(_ctx.title), 3),
      (_ctx.mode === 'login')
        ? (_openBlock(), _createBlock(_component_login_form, {
            key: 0,
            class: _normalizeClass({
          slide_out: _ctx.slide_out,
          slide_in: _ctx.slide_in,
        }),
            onEsqueceu: _cache[0] || (_cache[0] = ($event: any) => (_ctx.esqueceu()))
          }, null, 8, ["class"]))
        : _createCommentVNode("", true),
      (_ctx.mode === 'recover')
        ? (_openBlock(), _createBlock(_component_recover_pass, {
            key: 1,
            class: _normalizeClass({
          slide_out: _ctx.slide_out,
          slide_in: _ctx.slide_in,
        }),
            onVoltar: _cache[1] || (_cache[1] = ($event: any) => (_ctx.resetLoginScreen()))
          }, null, 8, ["class"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("img", {
          class: "login_cliente_imagem",
          src: _ctx.imgCliente,
          alt: "logo_empresa"
        }, null, 8, _hoisted_5)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("img", {
          style: {"width":"100%"},
          src: _ctx.imgImplanta,
          alt: "logo_empresa"
        }, null, 8, _hoisted_7)
      ])
    ])
  ]))
}