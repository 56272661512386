
import { defineComponent } from "vue";
import MainNav from "../components/MainTemplate/MainNav.vue";
import LoginView from "./LoginView.vue";

export default defineComponent({
  name: "MainView",

  components: {
    MainNav,
    LoginView,
  },
});
