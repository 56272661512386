
import { defineComponent } from "vue";

export default defineComponent({
  data: () => {
    return {
      showMenu: false,
      user: null as any,
      initials: null as any,

      trocarSenha: false,

      hideSenhaAtual: true,
      hideNovaSenha: true,
      hideConfirmarSenha: true,

      salvarSenhaLoading: false,

      senhaAtual: "",
      novaSenha: "",
      confirmarNovaSenha: "",
      sucessoSalvar: false,
      erroSalvar: false,

      passwordRules: [
        (v: string) => !!v || "Campo obrigatório",
        (v: string) =>
          (v && v.length >= 6) || "Senha deve ter pelo menos 6 caracteres.",
        (v: string) =>
          /[a-z]/i.test(v) || "Senha deve conter pelo menos uma letra",
        (v: string) => /\d/.test(v) || "Senha deve conter pelo menos um número",
        (v: string) =>
          /[-!$%^&*()_+|~=`{}[\]:";'@<>?,./]/.test(v) ||
          "Senha deve conter pelo menos um caractere especial (!$%^&*()_+@...)",
      ],

      passOldRule: [(v: string) => !!v || "Campo obrigatório"],
    };
  },
  computed: {
    errorSenhasDiferentes() {
      return this.novaSenha !== this.confirmarNovaSenha;
    },

    faltaCampo() {
      return !(this.senhaAtual && this.novaSenha && this.confirmarNovaSenha);
    },

    erroRegraSenha() {
      let rule1 = !this.novaSenha;
      let rule2 = !(this.novaSenha.length >= 6);
      let rule3 = !/[a-z]/i.test(this.novaSenha);
      let rule4 = !/\d/.test(this.novaSenha);
      let rule5 = !/[-!$%^&*()_+|~=`{}[\]:";'@<>?,./]/.test(this.novaSenha);

      return rule1 || rule2 || rule3 || rule4 || rule5;
    },
  },
  mounted() {
    this.showMenu = true;

    const userValue: string | null = localStorage.getItem("userData") ?? "";

    this.user = JSON.parse(userValue);

    this.initials = this.getInitials(this.user?.nome);
  },
  methods: {
    closeMenu() {
      this.showMenu = false;
      setTimeout(() => {
        this.$store.commit("defineDesktopMenu", false);
      }, 100);
    },

    getInitials(fullName: string): string {
      const names = fullName
        .split(" ")
        .filter((name) => !["da", "de", "do"].includes(name));
      let initials = "";

      if (names.length > 0) {
        initials += names[0][0];
      }

      if (names.length > 1) {
        initials += names[1][0];
      }

      return initials.toUpperCase();
    },

    salvarSenha() {
      let params = {
        email: this.user.email,
        senha: this.novaSenha,
      };
      this.salvarSenhaLoading = true;
      this.$axios
        .post("/Usuarios/UpdatePassword", params)
        .then(() => {
          this.trocarSenha = false;
          this.sucessoSalvar = true;
          setTimeout(() => {
            this.sucessoSalvar = false;
          }, 5000);
        })
        .catch(() => {
          this.erroSalvar = true;
          setTimeout(() => {
            this.erroSalvar = false;
          }, 5000);
        })
        .finally(() => {
          this.senhaAtual = "";
          this.novaSenha = "";
          this.confirmarNovaSenha = "";
          this.salvarSenhaLoading = false;
        });
    },
  },
});
