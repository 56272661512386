
import router from "@/router";
import { defineComponent } from "vue";
import { loginResponse } from "../../interfaces/login";

export default defineComponent({
  data() {
    return {
      email: "",
      senha: "",
      hidePassword: true,

      buttonLoading: false,

      ErrorLogin: false,

      buttonLabel: "Entrar",
    };
  },
  methods: {
    login() {
      this.buttonLoading = true;

      let params = {
        email: this.email,
        senha: this.senha,
      };

      this.$axios
        .post("/Usuarios/login", params)
        .then((res: loginResponse) => {
          this.setToken(res.data.token);

          this.setUser(res.data.usuario);

          this.buttonLoading = false;

          this.$store.commit("defineLogged", true);

          router.push({ name: "home" });
        })
        .catch(() => {
          this.loginError();
        });
    },

    loginError() {
      this.buttonLoading = false;
      this.ErrorLogin = true;

      this.buttonLabel = "E-mail e/ou senha incorretos";
      this.resetLabel();
    },

    resetLabel() {
      setTimeout(() => {
        this.buttonLabel = "Entrar";
        this.ErrorLogin = false;
      }, 4000);
    },
    setUser(user: object) {
      localStorage.setItem("userData", JSON.stringify(user));
    },

    setToken(token: string) {
      const expirationTime = 24 * 60 * 60 * 1000;

      const expirationDate = new Date(new Date().getTime() + expirationTime);

      const cookieString = `session=${token}; expires=${expirationDate.toUTCString()}; path=/; SameSite=Strict`;

      document.cookie = cookieString;
      this.$axios.defaults.headers.Authorization = `Bearer ${token}`;
    },
  },
});
