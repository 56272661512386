
import { defineComponent } from "vue";
import MenuIcon from "./MenuIcon.vue";
import UserMenu from "./UserMenu.vue";
import NavMenus from "./NavMenus.vue";
import SearchBar from "./SearchBar.vue";

export default defineComponent({
  name: "MainNav",

  components: {
    MenuIcon,
    UserMenu,
    NavMenus,
    SearchBar,
  },

  data() {
    return {
      imgCliente: require("../../assets/logo_cliente.png"),
      imgImplanta: require("../../assets/logo_implanta.png"),

      loginOut: false,

      isMobile: true,

      navMobileOpened: false,

      navDesktopOpened: true,

      screenWidth: window.innerWidth,
    };
  },
  watch: {
    "$store.state.redirectRoute": function () {
      this.navMobileOpened = false;
    },
  },
  mounted() {
    if (this.screenWidth >= 960) {
      this.isMobile = false;

      this.$store.commit("defineMobile", false);
    }
    window.addEventListener("resize", this.updateScreenWidth);

    let nw = document.documentElement.style.getPropertyValue("--nav-width");

    if (nw === "300px") {
      this.navDesktopOpened = true;
    } else {
      this.navDesktopOpened = false;
    }
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateScreenWidth);
  },

  methods: {
    toggleNavBar() {
      if (this.navMobileOpened) {
        this.navMobileOpened = false;
      } else {
        this.navMobileOpened = true;
      }
    },

    openUserMenu(tipo: string) {
      if (tipo === "mobile") {
        this.$store.commit("defineMobileMenu", !this.$store.state.menuMobile);
      }
      if (tipo === "desk") {
        this.$store.commit("defineDesktopMenu", !this.$store.state.menuDesktop);
      }
    },

    toggleNavDesktop() {
      let nw = document.documentElement.style.getPropertyValue("--nav-width");

      if (nw === "300px") {
        document.documentElement.style.setProperty("--nav-width", "70px");
        this.$store.commit("navMini", true);
      } else {
        document.documentElement.style.setProperty("--nav-width", "300px");
        this.$store.commit("navMini", false);
      }

      if (this.navDesktopOpened) {
        this.navDesktopOpened = false;
      } else {
        this.navDesktopOpened = true;
      }
    },

    updateScreenWidth() {
      this.screenWidth = window.innerWidth;

      if (this.screenWidth >= 960) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    },
    logout() {
      this.$router.push("/login");
      this.$store.commit("defineLogged", false);

      document.cookie = "session=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    },
  },
});
