import { createStore } from "vuex";

interface Filtros {
  Regiao: number[];
  Estado: number[];
  Municipio: number[];
  GrupoEmpresarial: number[];
  Revenda: number[];
  LinhaProduto: number[];
  CategoriaProduto: number[];
  FamiliaProduto: number[];
  Produto: number[];
}

export default createStore({
  state: {
    pdVsPositivados: null,
    pdVsPositivadosLoading: true,
    snack: {
      text: "",
      active: false,
      type: "",
    },
    filtros: {
      Regiao: [],
      Estado: [],
      Municipio: [],
      GrupoEmpresarial: [],
      Revenda: [],
      LinhaProduto: [],
      CategoriaProduto: [],
      FamiliaProduto: [],
      Produto: [],
    } as Filtros,

    mapMeses: {
      Janeiro: { abreviado: "Jan", completo: "Janeiro", order: 1 },
      janeiro: { abreviado: "Jan", completo: "Janeiro", order: 1 },
      January: { abreviado: "Jan", completo: "Janeiro", order: 1 },
      january: { abreviado: "Jan", completo: "Janeiro", order: 1 },
      "1": { abreviado: "Jan", completo: "Janeiro", order: 1 },
      "01": { abreviado: "Jan", completo: "Janeiro", order: 1 },

      Fevereiro: { abreviado: "Fev", completo: "Fevereiro", order: 2 },
      fevereiro: { abreviado: "Fev", completo: "Fevereiro", order: 2 },
      February: { abreviado: "Fev", completo: "Fevereiro", order: 2 },
      february: { abreviado: "Fev", completo: "Fevereiro", order: 2 },
      "2": { abreviado: "Fev", completo: "Fevereiro", order: 2 },
      "02": { abreviado: "Fev", completo: "Fevereiro", order: 2 },

      Marco: { abreviado: "Mar", completo: "Março", order: 3 },
      marco: { abreviado: "Mar", completo: "Março", order: 3 },
      Março: { abreviado: "Mar", completo: "Março", order: 3 },
      março: { abreviado: "Mar", completo: "Março", order: 3 },
      March: { abreviado: "Mar", completo: "Março", order: 3 },
      march: { abreviado: "Mar", completo: "Março", order: 3 },
      "3": { abreviado: "Mar", completo: "Março", order: 3 },
      "03": { abreviado: "Mar", completo: "Março", order: 3 },

      abril: { abreviado: "Abr", completo: "Abril", order: 4 },
      Abril: { abreviado: "Abr", completo: "Abril", order: 4 },
      April: { abreviado: "Abr", completo: "Abril", order: 4 },
      april: { abreviado: "Abr", completo: "Abril", order: 4 },
      "4": { abreviado: "Abr", completo: "Abril", order: 4 },
      "04": { abreviado: "Abr", completo: "Abril", order: 4 },

      Maio: { abreviado: "Mai", completo: "Maio", order: 5 },
      maio: { abreviado: "Mai", completo: "Maio", order: 5 },
      May: { abreviado: "Mai", completo: "Maio", order: 5 },
      may: { abreviado: "Mai", completo: "Maio", order: 5 },
      "5": { abreviado: "Mai", completo: "Maio", order: 5 },
      "05": { abreviado: "Mai", completo: "Maio", order: 5 },

      Junho: { abreviado: "Jun", completo: "Junho", order: 6 },
      junho: { abreviado: "Jun", completo: "Junho", order: 6 },
      June: { abreviado: "Jun", completo: "Junho", order: 6 },
      june: { abreviado: "Jun", completo: "Junho", order: 6 },
      "6": { abreviado: "Jun", completo: "Junho", order: 6 },
      "06": { abreviado: "Jun", completo: "Junho", order: 6 },

      Julho: { abreviado: "Jul", completo: "Julho", order: 7 },
      julho: { abreviado: "Jul", completo: "Julho", order: 7 },
      July: { abreviado: "Jul", completo: "Julho", order: 7 },
      july: { abreviado: "Jul", completo: "Julho", order: 7 },
      "7": { abreviado: "Jul", completo: "Julho", order: 7 },
      "07": { abreviado: "Jul", completo: "Julho", order: 7 },

      Agosto: { abreviado: "Ago", completo: "Agosto", order: 8 },
      agosto: { abreviado: "Ago", completo: "Agosto", order: 8 },
      August: { abreviado: "Ago", completo: "Agosto", order: 8 },
      august: { abreviado: "Ago", completo: "Agosto", order: 8 },
      "8": { abreviado: "Ago", completo: "Agosto", order: 8 },
      "08": { abreviado: "Ago", completo: "Agosto", order: 8 },

      Setembro: { abreviado: "Set", completo: "Setembro", order: 9 },
      setembro: { abreviado: "Set", completo: "Setembro", order: 9 },
      September: { abreviado: "Set", completo: "Setembro", order: 9 },
      september: { abreviado: "Set", completo: "Setembro", order: 9 },
      "9": { abreviado: "Set", completo: "Setembro", order: 9 },
      "09": { abreviado: "Set", completo: "Setembro", order: 9 },

      Outubro: { abreviado: "Out", completo: "Outubro", order: 10 },
      outubro: { abreviado: "Out", completo: "Outubro", order: 10 },
      October: { abreviado: "Out", completo: "Outubro", order: 10 },
      october: { abreviado: "Out", completo: "Outubro", order: 10 },
      "10": { abreviado: "Out", completo: "Outubro", order: 10 },

      Novembro: { abreviado: "Nov", completo: "Novembro", order: 11 },
      novembro: { abreviado: "Nov", completo: "Novembro", order: 11 },
      November: { abreviado: "Nov", completo: "Novembro", order: 11 },
      november: { abreviado: "Nov", completo: "Novembro", order: 11 },
      "11": { abreviado: "Nov", completo: "Novembro", order: 11 },

      Dezembro: { abreviado: "Dez", completo: "Dezembro", order: 12 },
      dezembro: { abreviado: "Dez", completo: "Dezembro", order: 12 },
      December: { abreviado: "Dez", completo: "Dezembro", order: 12 },
      december: { abreviado: "Dez", completo: "Dezembro", order: 12 },
      "12": { abreviado: "Dez", completo: "Dezembro", order: 12 },
    },

    sessaoExpirada: false,

    logged: true,

    filterChange: 1,

    navMini: false,

    isMobile: true,

    menuMobile: false,
    menuDesktop: false,

    redirectRoute: 1,
  },
  getters: {},
  mutations: {
    defineSnack(state, snack) {
      state.snack = snack;
    },

    defineMobile(state, isMobile) {
      state.isMobile = isMobile;
    },

    defineSessaoExpirada(state, sessaoExpirada) {
      state.sessaoExpirada = sessaoExpirada;
    },

    defineLogged(state, logged) {
      state.logged = logged;
    },

    addFiltro(
      state,
      payload: {
        nomeFiltro: keyof Filtros;
        code: number;
      },
    ) {
      const { nomeFiltro, code } = payload;
      state.filtros[nomeFiltro].push(code);
    },

    removeFilter(state, payload: { nomeFiltro: keyof Filtros; code: number }) {
      const { nomeFiltro, code } = payload;
      state.filtros[nomeFiltro] = state.filtros[nomeFiltro].filter(
        (c) => c !== code,
      );
    },

    clearFilters(state, nomeFiltro: keyof Filtros) {
      state.filtros[nomeFiltro] = [];
    },

    defineChangeFilter(state, change) {
      state.filterChange += change;
    },

    navMini(state, value) {
      state.navMini = value;
    },

    routeRedirected(state, change) {
      state.redirectRoute += change;
    },

    defineMobileMenu(state, value) {
      state.menuMobile = value;
    },

    defineDesktopMenu(state, value) {
      state.menuDesktop = value;
    },
  },
  actions: {},
  modules: {},
});
